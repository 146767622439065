


.container{
	width:100%;
	margin:auto;
	overflow: hidden;


}
.logo{
    left: 20px;
    top: -20px;
    position: relative;
    height: 100%;
    width: auto;
}
.navBar{
    min-height: 5vh;
}
.brother{
	font-size: 48px;
	color: rgb(48, 160, 121);
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	margin-right:0px;
	display:inline-block;
	margin-top: 30px;
	position: relative;
	animation-name: anim1;
	animation-duration: 4s;
}

@keyframes anim1{
	0% {color: #0e0b16;}
	25% {color:rgb(48, 160, 121);}
	100% {color: rgb(48, 160, 121);}
}

@keyframes anim2{
	0% {color: #0e0b16;}
	25% {color:white;}
	75% {color:rgb(48, 160, 121);}
	100% {color: white;}
}
.crew{
	font-size: 48px;
	color: white;
	margin-bottom: 0.2em;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	display:inline-block;
	margin-left: 0px;
	margin-top:30px;
	position: relative;
	animation-name: anim2;
	animation-duration: 6s;
}


.brocrew{
	margin-top:3px;
	margin-bottom: 15px;
    color: white;
}





.logout{
    text-decoration: none;
	font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	letter-spacing: 3px;
	font-size: 20px;
	right: 20px;
    top: 3vh;
	color: rgb(119, 118, 118);
    position: absolute;
    transition: 0.3s;
    width: 100px;

}
.logout:hover{
    cursor: pointer;
    color: white;
    
}
.nav-labels{
    font-size: 22px;
    letter-spacing: 3px;
    margin-left: 30px;
    
}
.nav-labels a{
    display: inline;
}
@media screen and (max-width: 993px) {
    .nav-labels a{
        display: block;
    }
    .nav-labels{
        margin-left: 10px;
    }
	.logout{
		position:relative;
		top: 10rem;
		right: -5rem;
	}
  }


.login{
	text-decoration: none;
	font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	letter-spacing: 6px;
	font-size: 20px;
	right: 20px;
    top: 20px;
	color: white;
    position: absolute; 
    width: 200px;
}
