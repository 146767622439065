@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.card{
    width: 200px;
    height: 200px;
    border-radius: 25px;
    background-color: #cec6b9;
    box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.623);
    transition: 0.3s;
    overflow: hidden;
    font-family: 'Lato', sans-serif;
}
.header{
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    height: 2rem;
    text-align: center;
    background-color: brown;
    color: white;

}
.card:hover{
    transform: scale(1.1);

}
ul{
    list-style-type:none;
    top: 20px;
    position: relative;
}
li{padding: 5px 0;}
.user-action{
    text-align: right;
    padding: 0 20px;
    margin-top: 1.5rem;
    position: relative;
}
.user-toggle{
    color: rgba(19, 117, 35, 0.589);
    transition: 0.3s;
}

.user-toggle:hover{
cursor: pointer;
transform: scale(1.2);
}
.user-label{
 color: rgb(156, 48, 34);
font-size: 15px;
cursor: pointer;
}
