.calendar-body{
    min-height: 90vh;
    width: 100%;
    background-color: rgb(48, 160, 121);

}
.days{ 
    margin-left: 0;
    margin-right: 0;
    position: relative;

}

.day{
    padding: 20px;
}
::-webkit-scrollbar {
    width: 15px;
  }
  
  ::-webkit-scrollbar-track {
    background: #383838a1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #353131;
    border-radius: 20px;
  
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #464236da;
  }
