.sidebar {
    height: 80vh;
    width: 90px;
    position: absolute;
    top: 20vh;
    
    overflow-x: hidden;
    padding-top: 60px;
    padding-bottom: 100px;
   
  overflow: hidden;
  }
  
